export const ppaVisualiserClassName = "ppa-enabled";

export const togglePPAVisualiser = () => {
  const hasStylesToggled = document.getElementById("ppa-visualiser-styles");
  if (hasStylesToggled) {
    hasStylesToggled.remove();
  } else {
    const style = document.createElement("style");
    style.id = "ppa-visualiser-styles";
    style.textContent = `.${ppaVisualiserClassName} { 
          border: 1px solid green; 
          box-sizing: border-box;
          position: relative;
        }
          
        .${ppaVisualiserClassName}::after {
            content: "";
            background: #0088014f;
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            pointer-events: none;
        }`;
    document.head.append(style);
  }
};
